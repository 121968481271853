import React from 'react'
import Footer from './Footer';

import gal1 from './img/ab.jpg'
import gal2 from './img/ab1.jpg'
import gal3 from './img/ab2.jpg'

import gal4 from './img/asu (1).jpg'
import gal5 from './img/asu (1).jpeg'
import gal6 from './img/asu (1).webp'

import gal7 from './img/asu (2).jpg'
import gal8 from './img/asu (3).jpg'
import gal9 from './img/banner1.jpg'

const Gallery = () => {
    return (
        <>
            <br />
            <center><h1>Gallery</h1></center>
            


            <div className='gallery'>
                <div className='gallery2'>
                    <img src={gal4} />
                </div>
                <div className='gallery2'>
                    <img src={gal5} />
                </div>
                <div className='gallery2'>
                    <img src={gal6} />
                </div>
            </div>


            <div className='gallery'>
                <div className='gallery2'>
                    <img src={gal1} />
                </div>
                <div className='gallery2'>
                    <img src={gal2} />
                </div>
                <div className='gallery2'>
                    <img src={gal3} />
                </div>
            </div>

            <div className='gallery'>
                <div className='gallery2'>
                    <img src={gal7} />
                </div>
                <div className='gallery2'>
                    <img src={gal8} />
                </div>
                <div className='gallery2'>
                    <img src={gal9} />
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Gallery