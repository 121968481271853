import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from './img/logo.png'

const Navbar = () => {
    const [expanded, setExpanded] = useState(false);

    const handleNavItemClick = () => {
        setExpanded(false);
    };

    return (
        <>
            <nav className="navbar navbar-expand-lg" style={{ backgroundColor: '#102a4e' }}>
                <div className="container-fluid">
                    <Link to="/" className="navbar-brand" style={{ marginLeft: '-15px', marginTop: '-10px' }}>
                        <img src={logo} />
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={() => setExpanded(!expanded)} style={{ color: 'white' }}>
                        <span className="navbar-toggler-icon" style={{ color: 'white' }}></span>
                    </button>

                    <div className={`collapse navbar-collapse ${expanded ? 'show' : ''}`} id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link to="/" className="nav-link" onClick={handleNavItemClick}>Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/About" className="nav-link" onClick={handleNavItemClick}>About</Link>
                            </li>

                            <li className="nav-item dropdown">
                                <Link to="#" className="nav-link dropdown-toggle" id="navbarDropdown4" role="button" data-bs-toggle="dropdown" aria-expanded="false"> Services</Link>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown4">
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>CORPORATE EVENTS</Link></li>
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}> BIRTHDAY PARTIES</Link></li>
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>WEDDING</Link></li>
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>DESTINATION WEDDING</Link></li>
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>RELIGIOUS EVENTS</Link></li>
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>COMMERCIAL SHOOTS</Link></li>
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>RING CEREMONY</Link></li>
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>ANNIVARSERY</Link></li>

                                </ul>
                            </li>




                            <li className="nav-item">
                                <Link to="/Facility" className="nav-link" onClick={handleNavItemClick}>Facility</Link>
                            </li>

                            <li className="nav-item">
                                <Link to="/Term" className="nav-link" onClick={handleNavItemClick}>Term & Conditions</Link>
                            </li>


                            <li className="nav-item">
                                <Link to="/Gallery" className="nav-link" onClick={handleNavItemClick}>Gallery</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/Register" className="nav-link" onClick={handleNavItemClick}>Contact</Link>
                            </li>

                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default Navbar;
