import React from 'react'
import corpo from './img/corpo.jpg';
import birth from  './img/birth.jpg';
import Wedding from  './img/wedding.jpg';
import distigate from  './img/diste.jpg';
import relegios from  './img/religios.jpg';
import commerce from './img/commerce.jpg';
import ring from './img/ring.jpg';
import annivsry from './img/aniversry.jpg';

const Service = () => {
    return (
        <>
            <br />
            <center><h1>Our Services</h1></center>

            <div className='service'>

                <div className='service2'>
                    <h1>CORPORATE EVENTS</h1>
                    <img src={corpo}/>
                </div>

                <div className='service2'>
                    <h1>
                        BIRTHDAY PARTIES</h1>
                        <img src={birth}/>
                </div>

                <div className='service2'>

                    <h1> WEDDING</h1>
                    <img src={Wedding}/>
                </div>

                <div className='service2'>
                    <h1> DESTINATION WEDDING</h1>
                    <img src={distigate}/>
                </div>


            </div>


            <div className='service'>

                <div className='service2'>
                    <h1>RELIGIOUS EVENTS</h1>
                    <img src={relegios}/>
                </div>

                <div className='service2'>
                    <h1>

                        COMMERCIAL SHOOTS</h1>
                        <img src={commerce}/>
                </div>

                <div className='service2'>

                    <h1> RING CEREMONY</h1>
                    <img src={ring}/>
                </div>

                <div className='service2'>
                    <h1>ANNIVARSERY</h1>
                    <img src={annivsry}/>
                </div>
            </div>



        </>
    )
}

export default Service