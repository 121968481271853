import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.js';

import Navbar from './component/Navbar';
import Home from './component/Home';
import Register from './component/Register';
import Head from './component/Head.js';
import About from './component/About.js';
import Gallery from './component/Gallery.js';
import Term from './component/Term.js';
import Facility from './component/Facility.js';

function App() {
  return (
    <div className='mains'>
      <Router>
        <Head />
        <Navbar />
        <Routes>

          <Route path="*" element={<Home />} />
          <Route path="/Register" element={<Register />} />
          <Route path="/About" element={<About />} />
          <Route path="/Gallery" element={<Gallery />} />
          <Route path="/Facility" element={<Facility />} />
          <Route path="/Term" element={<Term />} />
          
        </Routes>

      </Router>
      
    </div>

  );
}

export default App;
