import React from 'react'

const Content = () => {
    return (
        <div className='content'>
            <h1>Every wedding is a Unique package that fits<br/>
                you as Perfectly as your new Enggement ring.</h1>
        </div>
    )
}

export default Content