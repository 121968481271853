import React from 'react'
import { Link } from 'react-router-dom';
import ab1 from './img/ab.jpg'
import ab2 from './img/ab1.jpg'
import ab3 from './img/ab2.jpg'
import ab4 from './img/ab3.jpg'
import ab5 from './img/banner5.jpg'
import ab6 from './img/banner4.jpg'


const Contain = () => {
    return (
        <>

            <div className='contain'>

                <div className='contain2'>
                    <h1>Welcome To <span>Avadh Club</span></h1>
                    <p> <mark className='colors'>Avadh Club</mark> situated in the heart of Lucknow, stands as a regal venue for marriages, exuding elegance and charm. Nestled amidst lush greenery, it offers a picturesque setting, perfect for celebrating love in a grand manner. The architecture, reminiscent of Lucknow's rich cultural heritage, adds a touch of royalty to every event hosted within its premises.</p>
                    <p>The grandeur of <mark className='colors'>Avadh Club</mark> is reflected in its spacious halls adorned with intricate detailing and luxurious furnishings, providing a majestic backdrop for wedding ceremonies and receptions. Its sprawling lawns, dotted with ornate fountains and manicured gardens, offer an enchanting outdoor venue for couples to exchange vows amidst nature's beauty.</p>
                    <p>The culinary experience at <mark className='colors'>Avadh Club</mark> is equally enchanting, with a team of expert chefs crafting delectable delicacies that tantalize the taste buds of guests. From traditional Awadhi cuisine to global culinary delights, the menu is curated to cater to diverse palates and preferences.
                    </p>
                    <p>Furthermore, the dedicated event planning team at <mark className='colors'>Avadh Club</mark> ensures that every aspect of the wedding is meticulously planned and executed to perfection, leaving couples and their guests with unforgettable memories of their special day</p>

                    <p>With its royal ambiance, impeccable service, and attention to detail,<mark className='colors'>Avadh Club</mark> stands as a symbol of sophistication and opulence, making it the ideal choice for couples seeking a truly majestic wedding venue in Lucknow.</p>
                    <Link to='/Register'><button className="button"><span> Contact Us  </span></button></Link>


                </div>

                <div className='contain3'>

                    <div className='about1'>
                        <div className='about2'>
                            <img src={ab1} />
                        </div>
                        <div className='about2'>
                            <img src={ab2} />
                        </div>
                    </div>

                    <div className='about1'>
                        <div className='about2'>
                            <img src={ab3} />
                        </div>
                        <div className='about2'>
                            <img src={ab4} />
                        </div>
                    </div>

                    <div className='about1'>
                        <div className='about2'>
                            <img src={ab5} />
                        </div>
                        <div className='about2'>
                            <img src={ab6} />
                        </div>
                    </div>


                </div>

            </div>

        </>
    )
}

export default Contain