import React from 'react'
import logo from "./img/logo.png";
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <>
            <div className='footer'>
                <div className='foot'>
                   <h1>AVADH CLUB</h1>
                    <p>Avadh Club, situated in the heart of Lucknow, stands as a regal venue for marriages, exuding elegance and charm. Nestled amidst lush greenery, it offers a picturesque setting, perfect for celebrating love in a grand manner. The architecture, reminiscent of Lucknow's rich cultural heritage, adds a touch of royalty to every event hosted within its premises.</p>
                </div>
                <div className='foot'>
                    <h1>Useful Link</h1>
                    <h2><Link to='/' className='link'>About</Link></h2>
                    <h2> <Link to='/' className='link'>Services</Link></h2>
                    <h2> <Link to='/' className='link'>facilities</Link></h2>
                    <h2> <Link to='/' className='link'>Contact</Link></h2>
                    <h2> <Link to='/' className='link'>Gallery</Link></h2>
                </div>
                <div className='foot'>
                    <h1>Reach Us</h1>
                    <p>+91 8840705269</p>
                    <p>+91 9670402299</p>
                    <p>avadhclub1122@gmail.com</p>
                    <p>Tiwari chauraha, Nahar Road, Infront of Indian  Oil  Petrol Pump, Jankipuram,(Sector-J)  Lucknow,  226031</p>
                </div>
            </div>

            <div className='disclamer'>
                @c 2024 All Reserved Avadh Club  Designed By Creative Digital World
            </div>

        </>
    )
}

export default Footer