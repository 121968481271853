import React, { useState, useEffect } from 'react';

import Banner0 from './img/banner0.jpg';
import banner3 from './img/asu (1).jpeg';
import banner4 from './img/asu (2).jpg';
import banner2 from './img/banner3.jpg';
import banner5 from './img/banner4.jpg';
import banner6 from './img/banner5.jpg';
import newbanner from './img/newbanner.jpg';

import { Link } from 'react-router-dom';

const Carousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const images = [ newbanner, Banner0, banner2, banner3,banner4,banner5,banner6];
  const intervalDuration = 4000; 

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === images.length - 1 ? 0 : prevSlide + 1));
  };

  useEffect(() => {
    const intervalId = setInterval(nextSlide, intervalDuration);
    return () => clearInterval(intervalId);
  }, []); 

  return (
    <>
      <div className="carousel-container" >
        <div className="carousel">
          <img src={images[currentSlide]} alt={`Slide ${currentSlide}`} style={{ width: '100%' }} />
        </div>
      </div>

      <div className="myDIV">
      <Link to='/Register' style={{textDecoration:'none'}}>  <a href=""  style={{textDecoration:'none'}}>Contact us </a></Link>
      </div>

      <div className="myDIV2">
        <a href='Tel:8840705269'><i class="fa fa-phone"></i> </a>
        <a href='https://wa.me/918840705269'> <i class="fa fa-whatsapp"></i> </a>
      </div>
    </>

  );
};

export default Carousel;
