import React, { useState } from 'react';
import Swal from 'sweetalert2';
import Footer from './Footer';
import Map from './Map';


const Register = () => {
  const [inpval, setInp] = useState({
    name: "",
    email: "",
    age: "",
    mobile: "",
    work: "",
    address: "",
    description: "",
  });

  const setData = (e) => {
    const { name, value } = e.target;
    setInp(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const sendEmailAndWhatsApp = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    // Send email
   
    // Send WhatsApp message
    let message = "Form Data:\n";
    for (let [key, value] of formData.entries()) {
      message += `${key}: ${value}\n`;
    }

    const whatsappNumber = "9670402299"; // Replace with your WhatsApp number
    const whatsappURL = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${encodeURIComponent(message)}`;
    window.open(whatsappURL, '_blank');

    // Reset form fields
    e.target.reset();

    // Show success message
    Swal.fire({
      icon: 'success',
      title: 'Data Submitted Successfully',
      showConfirmButton: false,
      timer: 1000 
    });
  };

  return (

   <>
<br/>
<center><h1>Contact</h1></center>


    <div className='container'>
      <form className='mt-5' onSubmit={sendEmailAndWhatsApp}>
        <div className='row'>
          <div className="mb-3 col-lg-6 col-12">
            <label htmlFor="name" className="form-label">Name</label>
            <input type="text" value={inpval.name} onChange={setData} name="name" className="form-control" id="name" required />
          </div>

          <div className="mb-3 col-lg-6 col-12">
            <label htmlFor="email" className="form-label">Email</label>
            <input type="email" value={inpval.email} onChange={setData} name="email" className="form-control" id="email" required />
          </div>

          <div className="mb-3 col-lg-6 col-12">
            <label htmlFor="age" className="form-label">Age</label>
            <input type="text" value={inpval.age} onChange={setData} name="age" className="form-control" id="age" required />
          </div>

          <div className="mb-3 col-lg-6 col-12">
            <label htmlFor="mobile" className="form-label">Mobile</label>
            <input type="text" value={inpval.mobile} onChange={setData} name="mobile" className="form-control" id="mobile" required />
          </div>

          <div className="mb-3 col-lg-6 col-12">
            <label htmlFor="work" className="form-label">Work</label>
            <input type="text" value={inpval.work} onChange={setData} name="work" className="form-control" id="work" required />
          </div>

          <div className="mb-3 col-lg-6 col-12">
            <label htmlFor="address" className="form-label">Address</label>
            <input type="text" value={inpval.address} onChange={setData} name="address" className="form-control" id="address" required />
          </div>

          <div className="mb-3 col-lg-12">
            <label htmlFor="description" className="form-label">Description</label>
            <textarea name="description" value={inpval.description} onChange={setData} className="form-control" id="description" rows="3" required></textarea>
          </div>

          <button type="submit" className="btn btn-primary">Submit</button>
        </div>
      </form>
    </div>

    <Map/>

    <Footer/>

    </>
  );
}

export default Register;
