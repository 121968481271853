import React from 'react'
import Carousal from '../component/Carousal';
import Contain from './Contain';
import Content from './Content';
import Service from './Service';
import Footer from './Footer';
import Map from './Map';
import Video from './Video';

const Home = () => {
  return (
    <>
      <div className='mains'>
        <Carousal />
        <Contain />
        <Content />
        <Service />
        <Video/>
        <Map/>
        <Footer />
      </div>
    </>
  )
}

export default Home