import React from 'react'

const Head = () => {
    return (
        <div className='head'>

            <div className='head2'>
                <a href=''><i class="fa fa-facebook-f"></i></a>
                <a href=''><i class="fa fa-instagram"></i></a>
                <a href=''><i class="fa fa-youtube-play"></i></a>
                <a href=''><i class="fa fa-envelope"></i></a>
                <a href='Tel:8840705269'>+91 8840705269</a>

            </div>




            <div className='head3'>
                <marquee direction="left" >
                Avadh Club situated in the heart of Lucknow, stands as a regal venue for marriages, exuding elegance and charm. Nestled amidst lush greenery, it offers a picturesque setting, perfect for celebrating love in a grand manner.
                </marquee>
            </div>
        </div>
    )
}

export default Head