import React from 'react'

const Map = () => {
    return (
        <div className='map'>

            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14228.089428306974!2d80.9621576!3d26.9345057!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39995704b61fbf85%3A0x9a41d266e63fbd0!2sAvadh%20Club!5e0!3m2!1sen!2sin!4v1713858882961!5m2!1sen!2sin" style={{ width: '100%', height: '100%' }}></iframe>
        </div>
    )
}

export default Map