import React from 'react'
import Contain from './Contain';
import Footer from './Footer';

const About = () => {
  return (
    <>
      <br />
      <center><h1>About </h1></center>
      <Contain />
      <Footer />

    </>
  )
}

export default About