import React from 'react'
import Footer from './Footer'

const Facility = () => {
  return (
    <>
    <div className='newfact'>
      <br />
      <center><h1>Facilities</h1></center>
      <div className='facility'>

        <p> 1. Covering 60,000 sqft land area</p>
        <p>2.  5500 sqft covered hall</p>
        <p>3. 18*166 Covered food stall</p>
        <p>4.  4Well furnished A.C rooms with attached bathrooms</p>
        <p>5. Tent, light decoration, Flower decoration</p>
        <p>6. Stage, Gate, Mandap, Passage</p>
        <p>7. Jaimaala(2), Milni maala 21</p>
        <p>8. D.J (2 sound bar)</p>
        <p>9. 2 Guard/Bouncer</p>
        <p>10. Flex for bride & groom name</p>
        <p>11. Temporary electricity connection</p>
        <p>12. D.G set(for necessary areas of function)</p>
        <p>13. Ample parking space available</p>
        <p>14. Diesel for D.G set will be paid by party</p>
        <p>15. Note:- Security deposit of Rs. 10,000 is charged---which is refundable after final bill only</p>

      </div>
      <hr />

      </div>
      <Footer />
    </>
  )
}

export default Facility