import React from 'react';
import vid1 from './video/vid1 (1).mp4';
import vid2 from './video/vid1 (2).mp4';
import vid3 from './video/vid1 (3).mp4';
import vid4 from './video/vid1 (4).mp4';
import vid5 from './video/vid1 (5).mp4';
import vid6 from './video/vid1 (6).mp4';
import vid7 from './video/vid1 (7).mp4';
import vid8 from './video/vid1 (8).mp4';

const Video = () => {
    return (
        <>
            <br />
            <center><h1>Our Videos </h1></center>
            <div className='media'>

                <div className='media2'>
                    <video controls width='300px'>
                        <source src={vid1} type='video/mp4' />
                    </video>
                </div>
                <div className='media2'>
                    <video controls>
                        <source src={vid2} type='video/mp4' />
                    </video>
                </div>

                <div className='media2'>
                    <video controls>
                        <source src={vid3} type='video/mp4' />
                    </video>
                </div>

                <div className='media2'>
                    <video controls>
                        <source src={vid4} type='video/mp4' />
                    </video>
                </div>

            </div>



            <div className='media'>

                <div className='media2'>
                    <video controls>
                        <source src={vid5} type='video/mp4' />
                    </video>
                </div>
                <div className='media2'>
                    <video controls>
                        <source src={vid6} type='video/mp4' />
                    </video>
                </div>

                <div className='media2'>
                    <video controls>
                        <source src={vid7} type='video/mp4' />
                    </video>
                </div>

                <div className='media2'>
                    <video controls>
                        <source src={vid8} type='video/mp4' />
                    </video>
                </div>

            </div>



        </>

    );
};

export default Video
