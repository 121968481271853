import React from 'react'
import Footer from './Footer'

const Term = () => {
  return (
    <>

      <div className='newfact'>
        <br /><br />

        <center><h1>नियम और शर्तें</h1></center>

        <div className='facility'>

          <p>• बुकिंग के लिए न्यूनतम 50,000 रुपये अग्रिम धनराशि जमा करना अनिवार्य है।</p>
          <p>• यदि अग्रिम धनराशि 50,000 से कम है तो बुकिंग के 20 दिन के अन्दर बाकी भुगतान जमा करना होगा।</p>
          <p>• बुकिंग की शेष धनराशि समारोह की तिथि से 15 दिन पहले जमा करनी होगी।</p>
          <p>• आबकारी विभाग के लाईसेंस FL-11 के बिना अल्कोहल का सेवन प्रतिबंधित है।</p>
          <p>• रुपये 20000 जमानत धनराशि जमा करनी होगी जिसकी वापसी समारोह समाप्ति के बाद हो जायेगी।</p>
          <p>• बुकिंग रद्द करने पर जमा धनराशि किसी भी परिस्थिति में वापस नहीं होगी।</p>
          <p>• किसी भी प्रकार की टूट या क्षति होने पर उसकी भरपाई मार्केट मूल्य के अनुसार होगी।</p>
          <p>• बुकिंग का समय दोपहर 1:00 बजे से अगले दिन प्रातः 8:00 बजे तक रहेगा।</p>
          <p>• सम्पूर्ण परिसर अगले दिन प्रातः 8:00 बजे तक अवश्य खाली करना होगा अन्यथा अगले दिन का चार्ज लग जायेगा।</p>
          <p>• परिसर के अन्दर मादक पदार्थो का सेवन वर्जित है।</p>
          <p>• समारोह में किसी भी प्रकार का अस्त्र-शस्त्र लाना वर्जित है।</p>
          <p>• डी०जे० का प्रयोग रात 10:00 बजे के बाद वर्जित है।</p>
          <p>• जनरेटर के लिए डीजल का भुगतान आयोजक को करना होगा।</p>
          <p>• किसी प्रकार का वाद विवाद होने पर उसका निर्णय लखनऊ न्यायालय द्वारा ही मान्य होगा।</p>
          <p>• किसी भी प्राकृतिक आपदा अथवा दुर्घटना की स्थिति में आयोजन न हो सकने पर प्रबंधन जिम्मेदार नहीं होगा।</p>
          <p>• परिसर के अन्दर अपने सामान एवं परिसर के बाहर वाहनों की सुरक्षा की पूर्ण जिम्मेदारी आयोजक व वाहन स्वामी की होगी।</p>
          <p>• परिसर के उपलब्ध कमरों हेतु तालों की व्यवस्था बुकिंग कर्ता को स्वयं करनी होगी।</p>
          <p>• बुकिंग स्वीकार या अस्वीकार करने का अंतिम अधिकार प्रबंधन का होगा।</p>
          <p>• परिसर में कमरों तक सामान पहुँचाने एवं रूम सर्विस की सुविधा उपलब्ध नहीं है।</p>
          <p>• प्रबन्ध समिति को समस्त कार्यक्रम किसी भी समय बंद करने का अधिकार सुरक्षित है।</p>
          <p>• जी०एस०टी० तथा अन्य कर नियम अनुसार देना होगा।</p>
          <p>मैं उपरोक्त नियम व शर्तों को भली भाँति अध्ययन कर लिया है तथा उपरोक्त नियम व शर्तें मुझे स्वीकार हैं।</p>



        </div>

        <hr />

      </div>
      <Footer />

    </>
  )
}

export default Term